import {
  useEffect,
  useMemo,
  useCallback,
  createContext,
  ReactElement,
  Dispatch,
  FunctionComponent,
} from "react";
import { useImmerReducer } from "use-immer";
import { publicIpv4 } from "public-ip";
import { useUserSession } from "../../../commons/hooks/useUserSession";
import {
  GlobalContextAction,
  globalContextReducer,
  globalContextInitialState,
} from "./globalContextState";
import { GlobalContextParams } from "../../../models/context/globalStateTypes";
import { GeoCoordinatesType } from "../../../models/google";
import Analytics from "../../../commons/helpers/analytics";

export enum globalContextActionTypes {
  "SET_HOME_PAGE_LOADING_STATUS" = "global_context@set_home_page_loading_status_action",
  "SET_AUTH_MODAL_STATUS" = "global_context@set_auth_modal_status_action",
  "SET_CREATE_PROPERTY_MODAL_STATUS" = "global_context@set_create_property_modal_status_action",
  "SET_EDIT_PROPERTY_MODAL_INIT_INFO" = "global_context@set_edit_property_modal_init_info_action",
  "SET_EMBED_PROPERTY_MODAL_INIT_INFO" = "global_context@set_embed_property_modal_init_info_action",
  "SET_AUTHENTICATION_STATUS" = "global_context@set_auth_status_action",
  "SET_AGENT_APPROVAL_STATUS" = "global_context@set_agent_approval_status_action",
  "SET_GEO_STATUS" = "global_context@set_geo_status_action",
  "SET_GEO_COORDINATES" = "global_context@set_geo_coordinates_action",
  "SET_PUBLIC_IP" = "global_context@set_public_ip_action",
  "SET_CREATE_PROPERTY_MODAL" = "global_context@set_create_property_modal_action",
  "SET_EDIT_PROFILE_MODAL_STATUS" = "global_context@set_edit_profile_modal_status_action",
  "SET_EDIT_PROFILE_MODAL_INIT_INFO" = "global_context@set_edit_profile_modal_init_info_action",
  "SET_PROPERTY_VIEW_INIT_INFO" = "global_context@set_property_view_init_info_action",
  "SET_VIDEO_MUTED_STATUS" = "global_context@set_video_muted_status_action",
  "SET_VIDEO_VOLUME" = "global_context@set_video_volume_action",
  "SET_SHARE_EMBED_INFO_MODAL" = "global_context@set_share_embed_info_modal_action",
  "INITIALISE_ALL" = "global_context@initialise_all_action",
  "REFRESH_PROPERTIES" = "global_context@refresh_properties_action",
  
}

interface GlobalContextProps {
  globalState: GlobalContextParams;
  globalDispatch: Dispatch<GlobalContextAction>;
}

export const GlobalContext = createContext<GlobalContextProps>({
  globalState: globalContextInitialState,
  globalDispatch: () => {},
});

interface GlobalProviderProps {
  children: ReactElement;
}

export const GlobalProvider: FunctionComponent<GlobalProviderProps> = ({
  children,
}) => {
  const [globalState, globalDispatch] = useImmerReducer(
    globalContextReducer,
    globalContextInitialState
  );

  const { getUser } = useUserSession();

  const ip = useCallback(async () => {
    const ip = await publicIpv4();
    globalDispatch({
      type: globalContextActionTypes.SET_PUBLIC_IP,
      payload: ip,
    });
  }, []);

  useEffect(() => {
    Analytics.init();

    const user = getUser();
    if (user && user.refreshToken) {
      Analytics.identify(user);
      globalDispatch({
        type: globalContextActionTypes.SET_AUTHENTICATION_STATUS,
        payload: true,
      });

      globalDispatch({
        type: globalContextActionTypes.SET_AGENT_APPROVAL_STATUS,
        payload: user.profile.is_verified,
      });
    }

    if ("geolocation" in navigator) {
      navigator.geolocation.watchPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          globalDispatch({
            type: globalContextActionTypes.SET_GEO_STATUS,
            payload: true,
          });
          globalDispatch({
            type: globalContextActionTypes.SET_GEO_COORDINATES,
            payload: { lat, lng } as GeoCoordinatesType,
          });
        },
        (error) => {
          if (error.code == error.PERMISSION_DENIED) {
            globalDispatch({
              type: globalContextActionTypes.SET_GEO_STATUS,
              payload: false,
            });
          }
        }
      );
    }

    ip();
  }, [ip]);

  const value = useMemo(() => {
    return {
      globalState,
      globalDispatch,
    };
  }, [globalState, globalDispatch]);

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};