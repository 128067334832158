import { QueryClient } from "react-query";
import { LoaderFunctionArgs } from "react-router-dom";
import axios from "axios";
import { PropertyViewConstants } from "../../../containers/propertyView/property.view.constants";
import { PUBLIC_BASE_URL, PUBLIC_API_KEY } from "../../constants/appBase";
import { GetPropertyByIdResponse } from "../../../models/propertyView/searchPropertyByIdResponse";
import { CampaignType } from "../../../models/enums";

const Axios = axios.create({
  baseURL: PUBLIC_BASE_URL,
  timeout: 90000,
  headers: {
    "Content-type": "application/json",
    "x-api-key": `${PUBLIC_API_KEY}`,
  },
});

const getPropertyByIdQuery = (propertyId: number | null) => ({
  queryKey: ["getPropertyById", propertyId],
  queryFn: async () => {
    if (!!propertyId) {
      const { data } = await Axios.get(
        PropertyViewConstants.SEARCH_PROPERTY_BY_ID(propertyId)
      );

      return data;
    }

    return null;
  },
});

export type propertyRouteLoaderProps = {
  isSplashEnabled: boolean;
  address?: string;
};

export const propertyRouteLoader =
  (queryClient: QueryClient) =>
  async ({
    request,
  }: LoaderFunctionArgs): Promise<propertyRouteLoaderProps> => {
    const url = new URL(request.url);
    const propertyId = url.searchParams.get("propertyId");
    const showType = url.searchParams.get("type");
    const query = getPropertyByIdQuery(
      propertyId ? parseInt(propertyId) : null
    );

    const data = (queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchQuery(query))) as GetPropertyByIdResponse | null;

    if (
      data?.property.campaign_type === CampaignType.Social ||
      showType === "embed"
    ) {
      return {
        isSplashEnabled: false,
      };
    }

    return {
      isSplashEnabled: true,
      address: `${data?.property.street}, ${data?.property.suburb}, ${data?.property.state}, ${data?.property.country}`,
    };
  };
