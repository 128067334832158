import * as amplitude from '@amplitude/analytics-browser';
import { AuthUserSession } from "../../models/auth";
import { AMPLITUDE_KEY, IS_NODE_PRODUCTION } from "../constants/appBase";

const IS_ANALYTICS_ENABLED = IS_NODE_PRODUCTION;

const init = () => {
  if (IS_ANALYTICS_ENABLED) {
    amplitude.init(AMPLITUDE_KEY as string);
  }
}

const reset = () => {
  if (IS_ANALYTICS_ENABLED) {
    amplitude.reset();
  }
}
const identify = (user: AuthUserSession) => {
  if (IS_ANALYTICS_ENABLED) {
    amplitude.setUserId(`user_id_${user?.profile?.id}`)
    const identifyEvent = new amplitude.Identify();
    user?.profile?.id && identifyEvent.set('phone', user?.profile?.id);
    user?.profile?.first_name && identifyEvent.set('first_name', user?.profile?.first_name);
    user?.profile?.last_name && identifyEvent.set('last_name', user?.profile?.last_name);
    user?.profile?.username && identifyEvent.set('username', user?.profile?.username);
    user?.profile?.role && identifyEvent.set('role', user?.profile?.role);
    user?.profile?.phone && identifyEvent.set('phone', user?.profile?.phone);
    user?.profile?.agent_email && identifyEvent.set('agent_email', user?.profile?.agent_email);
    user?.profile?.is_verified && identifyEvent.set('is_verified', user?.profile?.is_verified);
    user?.profile?.country && identifyEvent.set('country', user?.profile?.country);
    user?.profile?.suburb && identifyEvent.set('suburb', user?.profile?.suburb);

    amplitude.identify(identifyEvent);
  }
}

const track = (eventInput: string, eventProperties?: Record<string, any>) => {
  if (IS_ANALYTICS_ENABLED) {
    amplitude.track(eventInput, eventProperties);
  }
}

const Analytics = {
  init,
  reset,
  identify,
  track
}

export default Analytics;
