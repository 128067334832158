import { lazy } from "react";
import { QueryClient } from "react-query";
import { Navigate, RouteObject } from "react-router-dom";
import { propertyRouteLoader } from "./commons/config/routeConfig/propertyRouteConfig";
import { agentRouteLoader } from "./commons/config/routeConfig/agentRouteConfig";

const AuthModalProvider = lazy(() => import("./containers/authView"));

const AppRoutes = (queryClient: QueryClient): RouteObject[] => {
  return [
    { path: "/", id: "root", element: <Navigate to={"/home"} replace /> },
    {
      path: "/home",
      id: "home",
      lazy: async () => {
        const { HomePage } = await import("./pages/home/HomePage");
        return { Component: HomePage };
      },
    },
    {
      path: "/property",
      id: "property",
      lazy: async () => {
        const { PropertyPage } = await import("./pages/property/PropertyPage");
        return { Component: PropertyPage };
      },
      loader: propertyRouteLoader(queryClient),
      errorElement: <Navigate to={"/home"} replace />,
    },
    {
      path: "/search",
      id: "search",
      lazy: async () => {
        const { SearchPage } = await import("./pages/search/SearchPage");
        return { Component: SearchPage };
      },
    },
    {
      path: "/profile/*",
      id: "profile",
      lazy: async () => {
        const { AgentPage } = await import("./pages/agent/AgentPage");
        return { Component: AgentPage };
      },
      loader: agentRouteLoader(queryClient),
      errorElement: <Navigate to={"/home"} replace />,
    },
    {
      path: "/all-agents",
      id: "allAgents",
      lazy: async () => {
        const { FollowedAgentsPage } = await import(
          "./pages/agent/FollowedAgentsPage"
        );
        return { Component: FollowedAgentsPage };
      },
    },
    {
      path: "/masterclasses",
      id: "masterclasses",
      lazy: async () => {
        const { MasterclassPage } = await import(
          "./pages/masterclass/MasterclassPage"
        );
        return { Component: MasterclassPage };
      },
    },
    {
      path: "/inbox",
      id: "inbox",
      lazy: async () => {
        const { ChatPage } = await import("./pages/chat/ChatPage");
        return { Component: ChatPage };
      },
    },
    {
      path: "/app-store",
      id: "appStore",
      lazy: async () => {
        const { RedirectPage } = await import("./pages/redirect/RedirectPage");
        return { Component: RedirectPage };
      },
    },
    /* Temp */
    {
      path: "/signup",
      id: "signupModal",
      element: (
        <AuthModalProvider
          isVisible={true}
          initRoute="ROLE_SELECTOR"
          initLoginStatus={false}
          onCancel={() => {}}
        />
      ),
    },
    { path: "*", element: <Navigate to={"/home"} replace /> },
  ];
};

export default AppRoutes;
