import { Draft } from "immer";
import { GlobalContextParams } from "../../../models/context/globalStateTypes";
import { globalContextActionTypes } from "./globalContext";
import { DEFAULT_LAT, DEFAULT_LNG } from "../../../commons/constants/appBase";

export type GlobalContextAction = {
  type: globalContextActionTypes;
  payload?: any;
};

export const globalContextInitialState: GlobalContextParams = {
  isHomePageLoading: false,
  isAuthModalShowing: false,
  isCreatePropertyShowing: false,
  isEditProfileShowing: false,
  isShareEmbedShowing: false,
  embedPropertyInfo: null,
  selectedAgentId: null,
  selectedProperty: null,
  selectedPropertyObj: {
    propertyId: null,
    location: null,
    requestPath: null,
  },
  isAgentApproved: false,
  isAuthenticated: false,
  isGeoEnabled: false,
  geoCoordinates: { lat: DEFAULT_LAT, lng: DEFAULT_LNG },
  publicIp: undefined,
  propertyRefreshStatus: false,
  isVideoMuted: true,
  videoVolume: 0,
};

export const globalContextReducer = (
  state: Draft<GlobalContextParams>,
  action: GlobalContextAction
) => {
  switch (action.type) {
    case globalContextActionTypes.SET_HOME_PAGE_LOADING_STATUS:
      state.isHomePageLoading = action.payload;
      return;
    case globalContextActionTypes.SET_AUTH_MODAL_STATUS:
      state.isAuthModalShowing = action.payload;
      return;
    case globalContextActionTypes.SET_CREATE_PROPERTY_MODAL_STATUS:
      state.isCreatePropertyShowing = action.payload;
      return;
    case globalContextActionTypes.SET_EDIT_PROFILE_MODAL_STATUS:
      state.isEditProfileShowing = action.payload;
      return;
    case globalContextActionTypes.SET_EDIT_PROFILE_MODAL_INIT_INFO:
      state.isEditProfileShowing = true;
      state.selectedAgentId = action.payload;
      return;
    case globalContextActionTypes.SET_EDIT_PROPERTY_MODAL_INIT_INFO:
      state.isCreatePropertyShowing = true;
      state.selectedProperty = action.payload.property;
      return;
    case globalContextActionTypes.SET_EMBED_PROPERTY_MODAL_INIT_INFO:
      state.isShareEmbedShowing = true;
      state.embedPropertyInfo = action.payload.property;
      return;
    case globalContextActionTypes.SET_PROPERTY_VIEW_INIT_INFO:
      state.selectedPropertyObj.propertyId = action.payload.propertyId;
      state.selectedPropertyObj.location = action.payload.location;
      state.selectedPropertyObj.requestPath = action.payload.requestPath;
      state.selectedPropertyObj.requestType = action.payload.requestType;
      state.selectedPropertyObj.requestAttribute =
        action.payload.requestAttribute;
      return;
    case globalContextActionTypes.SET_AUTHENTICATION_STATUS:
      state.isAuthenticated = action.payload;
      return;
    case globalContextActionTypes.SET_AGENT_APPROVAL_STATUS:
      state.isAgentApproved = action.payload;
      return;
    case globalContextActionTypes.SET_GEO_STATUS:
      state.isGeoEnabled = action.payload;
      return;
    case globalContextActionTypes.SET_GEO_COORDINATES:
      state.geoCoordinates = action.payload;
      return;
    case globalContextActionTypes.SET_PUBLIC_IP:
      state.publicIp = action.payload;
      return;
    case globalContextActionTypes.SET_CREATE_PROPERTY_MODAL:
      state.selectedProperty = null;
      state.isCreatePropertyShowing = false;
      return;
    case globalContextActionTypes.SET_VIDEO_MUTED_STATUS:
      state.isVideoMuted = action.payload;
      return;
    case globalContextActionTypes.SET_VIDEO_VOLUME:
      state.videoVolume = action.payload;
      return;
    case globalContextActionTypes.SET_SHARE_EMBED_INFO_MODAL:
      state.embedPropertyInfo = null;
      state.isShareEmbedShowing = false;
      return;
    case globalContextActionTypes.INITIALISE_ALL:
      return globalContextInitialState;
    case globalContextActionTypes.REFRESH_PROPERTIES:
      state.propertyRefreshStatus = action.payload;
      return;
    default:
      return state;
  }
};