export const colors = {
  primary: "#EA3656",
  primary08: "#EA3656cc",
  secondary: "#CA467C",
  secondaryLight: "#EB286EB2",
  secondaryLighter: "#BB90A7",
  white: "#FFFFFF",
  white07: "#ffffffb3",
  white01: "rgba(255,255,255,0.1)",
  primaryBlack: "#232323",
  siderBlack: "#545454",
  black: "#000000",
  black09: "#000000e6",
  black08: "#000000cc",
  black075: "#000000bf",
  black07: "#000000b3",
  black06: "#00000099",
  black05: "#00000080",
  black04: "#00000066",
  black03: "#0000004d",
  black02: "#00000033",
  black012: "#0000001f",
  black01: "#0000001a",
  black005: "#0000000d",
  black004: "#0000000A",
  black002: "#00000006",
  blue: "#3894FF",
  dodgerBlue: "#1E90FF",
  iphoneBlue: "#007AFF",
  gray: "#808080",
  gray01: "#9d9d9d",
  gray05: "#6b6b6b80",
  grayMed: "#a9a9a9",
  grayMed1: "#6F6D6D",
  grayMedDark: "#363636",
  grayDark: "#222222",
  grayDark1: "#7D7D7D",
  grayDark2: "#333333",
  red: "#FF0000",
  redWarning: "#D81B60",
  green: "#008000",
  grayMedlight: "#DDDDDD",
  grayMedlight2: "#e5e5e5",
  grayMedlight3: "#A0A0A0",
  grayMedlight4: "#DDDFE4",
  grayMedlight5: "#CCCCCC",
  grayMedlight6: "#9A9A9A",
  grayMedlight7: "#979797",
  grayMedlight8: "#DAD7D8",
  grayMedlight9: "#BCB9BB",
  grayMedlight10: "#B1AFAF",
  grayMedlight11: "#969696",
  grayMedlight12: "#696969",
  grayMedlight13: "#949494",
  grayMedlight14: "#999999",
  red01: "rgba(255, 0, 0, 0.1)",
  red02: "rgba(255, 0, 0, 0.4)",
  graylight: "#F1F0EF",
  graylight1: "#F0F2F5",
  graylight2: "#f5f5f5",
  graylight3: "#2C2C2C",
  graylight4: "#EAEAEA",
  graylight5: "#C1C1C1",
  graylight6: "#D3D3D3",
  graylight7: "#E9E9E9",
  graylight8: "#F0EDED",
  gradientEnd: "#FF5039",
  gradientStart: "#E8048C",
  placeholderColor: "#00000066",
  inputUnderlineColor: "#00000080",
  defaultBorderColor: "transparent",
  transparent: "transparent",
  bottomBarGradientStart: "rgba(232,4,140,0.75)",
  bottomBarGradientMedium: "rgba(248,58,113,0.75)",
  bottomBarGradientEnd: "rgba(255,108,88,0.75)",
  progressBarGradientStart: "rgba(232,4,140,1.0)",
  progressBarGradientMedium: "rgba(255,38,168,1.0)",
  progressBarGradientEnd: "rgba(169,169,169,1.0)",
  dimgray: "dimgray",
  darkgray: "darkgray",
  avatarColors: ["#FF4F4F", "#E8048C", "#007AFF", "#008000"],
  yellow: "#FFAF35",
  // chatView Color schemes
  grey1: "#646363",
  grey2: "#c4c4c4",
  black10: "#201F1F",
  pinkishRed: "#ff3257",
};
