import { AuthUserSession } from "../../models/auth";

export const USER_SESSION_KEY = "USER_SESSION_KEY";

export const useUserSession = () => {
  const saveUser = (data: Partial<AuthUserSession>) => {
    window.localStorage.setItem(USER_SESSION_KEY, JSON.stringify(data));
  };

  const getUser = (): AuthUserSession | null => {
    const userSessionRawData = window.localStorage.getItem(USER_SESSION_KEY);
    const userSession: AuthUserSession = userSessionRawData
      ? JSON.parse(userSessionRawData)
      : null;
    return userSession;
  };

  const removeUser = (): void => {
    window.localStorage.removeItem(USER_SESSION_KEY);
  };

  return {
    saveUser,
    getUser,
    removeUser,
  };
};
