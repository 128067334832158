export enum SegmentedControlCampaignType {
  Buy = "For Sale",
  Rental = "Rental",
  Sold = "Sold",
  Rented = "Rented",
  Social = "Social",
}

export enum PropertyTileCampaignKeys {
  Buy = "SALE",
  Rental = "RENT",
  Sold = "SOLD",
  Rented = "LEASED",
}

export enum FilterType {
  All = "All",
  House = "House",
  Villa = "Villa",
  Townhouse = "Townhouse",
  Apartment = "Apartment",
  Studio = "Studio",
}

export enum CampaignType {
  Buy = "PRIVATE_SALE",
  Rental = "RENTAL",
  Sold = "SOLD",
  Rented = "RENTED",
  Social = "SOCIAL",
}

export enum HomeViewTabKeys {
  Discover = "discover",
  Luxury = "luxury",
  Following = "following",
  AroundMe = "around_me",
}

export enum SearchViewTabKeys {
  All = "all",
  Property = "property",
  Agents = "agents",
  Keywords = "keywords",
}

export enum SideMenuKeys {
  Toggle = "toggle",
  Home = "homePage",
  Search = "searchPage",
  Luxury = "luxuryPage",
  Following = "followingPage",
  Masterclasses = "masterclasses",
  Inbox = "inbox",
}

export enum AgentPropertyKeys {
  Active = "Current Properties",
  History = "History",
  Draft = "Offline / Drafts",
}

export enum AreaUnitKeys {
  Sqm = "Sqm",
  Sqft = "Sqft",
  Acres = "Acres",
  Hectares = "Hectares",
  Yards = "Yards",
}

export enum RentFrequencyKeys {
  Weekly = "Weekly",
  Monthly = "Monthly",
  Yearly = "Yearly",
}

export enum VideoType {
  Main = "main",
  Entrance = "entrance",
  Bedroom = "bedroom",
  Bathroom = "bathroom",
  Kitchen = "kitchen",
  Lounge = "lounge",
  Dining = "dining",
}

export enum TransitType {
  EXTERNAL = "EXTERNAL",
  INTERNAL = "INTERNAL",
}

export enum AgentTrendingMode {
  SUBURB = "SUBURB",
  NAME = "NAME",
}

export enum SelectedPropertyLocationType {
  Home = "home?section=discover",
  Luxury = "home?section=luxury",
  Following = "home?section=following",
  SearchProperty = "search?section=property",
  SearchKeywords = "search?section=keywords",
  SearchAll = "search?section=all",
  Profile = "profile",
}

export enum SortChatOptions {
  unread = "unread",
  time = "time",
  price = "price",
}

export enum TimeCode {
  PM = "PM",
  AM = "AM",
}
