import { CAMPAIGN_TYPE } from "../../models/propertyView/propertyBase";

export const AgentViewConstants = {
  GET_AGENT_PROFILE_URL: (agentId: number) =>
    `/np/api/v1/agents/profile?agentId=${agentId}`,

  GET_AGENT_PROFILE_URL_BY_USERNAME: (username: string) =>
    `/np/api/v1/agents/profile?username=${username}`,

  GET_PROPERTY_BY_AGENT_ID_URL_V1: (
    agentId: number,
    type: CAMPAIGN_TYPE | undefined,
    isLive?: boolean
  ) =>
    `/np/api/v1/agents/properties?includeVideos=true&${
      type ? `campaignType=${type}&` : ""
    }limit=${AgentViewConstants.DEFAULT_PAGE_SIZE}&status=${
      isLive ? "true" : "false"
    }&agentId=${agentId}`,

  GET_PROPERTY_BY_AGENT_ID_URL_V2: (
    queryString: string,
    start: number,
    type: CAMPAIGN_TYPE | undefined,
    targetLat: number | undefined,
    targetLng: number | undefined
  ) => {
    const locationParam =
      targetLat && targetLng
        ? `&targetLat=${targetLat.toString()}&targetLng=${targetLng.toString()}`
        : "";
    const campaignTypeParam = type ? `campaignType=${type}&` : "";

    return `/np/api/v2/properties/search/byAgentId?${campaignTypeParam}${queryString}&start=${start}&limit=${AgentViewConstants.DEFAULT_PAGE_SIZE}${locationParam}&geoMode=GLOBAL`;
  },

  FOLLOWING_AGENT_URL: (agentId: number) =>
    `/np/api/v1/follows/add?userId=${agentId}`,

  UNFOLLOWING_AGENT_URL: (agentId: number) =>
    `/np/api/v1/follows/remove?userId=${agentId}`,

  DEFAULT_PAGE_SIZE: 250,
};
