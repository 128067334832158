import { FunctionComponent, Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider, setLogger } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { message, notification } from "antd";
import { IS_NODE_PRODUCTION } from "./commons/constants/appBase";
import LoadingPane from "./components/Commons/LoadingPane/LoadingPane";
import AppRoutes from "./App.routes";

setLogger({
  log: IS_NODE_PRODUCTION ? window.console.clear : window.console.log,
  warn: IS_NODE_PRODUCTION ? window.console.clear : window.console.warn,
  error: IS_NODE_PRODUCTION ? window.console.clear : window.console.error,
});

message.config({ maxCount: 1 });
notification.config({ maxCount: 1 });

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retryDelay: (attemptIndex) => Math.round(1000 * Math.exp(attemptIndex)),
    },
  },
});

const router = createBrowserRouter(AppRoutes(queryClient));

const App: FunctionComponent = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<LoadingPane />}>
        {/* <TransitionGroup>
          <CSSTransition timeout={300} unmountOnExit appear>

          </CSSTransition>
        </TransitionGroup> */}
        <RouterProvider router={router} />
      </Suspense>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
