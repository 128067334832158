import { QueryClient } from "react-query";
import { LoaderFunctionArgs } from "react-router-dom";
import axios from "axios";
import { AgentViewConstants } from "../../../containers/agentView/agent.vew.constants";
import { PUBLIC_BASE_URL, PUBLIC_API_KEY } from "../../constants/appBase";
import { GetAgentProfileResponse } from "../../../models/agent/agentTypes";
import { CampaignType } from "../../../models/enums";

const Axios = axios.create({
  baseURL: PUBLIC_BASE_URL,
  timeout: 90000,
  headers: {
    "Content-type": "application/json",
    "x-api-key": `${PUBLIC_API_KEY}`,
  },
});

const getAgentProfileByUsernameQuery = (username: string | null) => ({
  queryKey: ["getAgentProfileByUsername", username],
  queryFn: async () => {
    if (!!username) {
      const { data } = await Axios.get(
        AgentViewConstants.GET_AGENT_PROFILE_URL_BY_USERNAME(
          username.includes("@") ? username.substring(1) : username
        )
      );

      return data?.data;
    }

    return null;
  },
});

export type agentRouteLoaderProps = {
  first_name?: string;
  last_name?: string;
};

export const agentRouteLoader =
  (queryClient: QueryClient) =>
  async ({ request }: LoaderFunctionArgs): Promise<agentRouteLoaderProps> => {
    const url = new URL(request.url);
    const pathname = url.pathname;
    const username =
      pathname.split("@").length >= 1 ? pathname.split("@")[1] : null;
    const query = getAgentProfileByUsernameQuery(username);

    const data = (queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchQuery(query))) as GetAgentProfileResponse | null;

    return {
      first_name: data?.user?.first_name,
      last_name: data?.user?.last_name,
    };
  };
