export const HOST_NAME = process.env.REACT_APP_CI_APP_HOST_NAME;
export const PUBLIC_BASE_URL = process.env.REACT_APP_CI_PUBLIC_BASE_URL;
export const PRIVATE_BASE_URL = process.env.REACT_APP_CI_PRIVATE_BASE_URL;
export const PUBLIC_API_KEY = process.env.REACT_APP_CI_PUBLIC_API_KEY;
export const IS_NODE_PRODUCTION = process.env.NODE_ENV === "production";
export const APP_ENV = process.env.REACT_APP_CI_APP_ENV;
export const PRIVACY_PDF_URL = process.env.REACT_APP_CI_PRIVACY_PDF_URL;
export const AGENT_TERMS_SERVICE_PDF_URL =
  process.env.REACT_APP_CI_AGENT_TERMS_SERVICE_PDF_URL;
export const USER_TERMS_SERVICE_PDF_URL =
  process.env.REACT_APP_CI_USER_TERMS_SERVICE_PDF_URL;
export const AMPLITUDE_KEY = process.env.REACT_APP_CI_AMPLITUDE_KEY;
export const VIDEO_SDK_LICENCE = process.env.REACT_APP_VIDEO_SDK_LICENCE;
export const DEFAULT_LAT = parseFloat(
  process.env.REACT_APP_CI_DEFAULT_LAT as string
);
export const DEFAULT_LNG = parseFloat(
  process.env.REACT_APP_CI_DEFAULT_LNG as string
);
export const SEARCH_TEXT_DEBOUNCE_RATE = 500;
export const KEYBOARD_DEBOUNCE_RATE = 300;
export const SCROLL_DEBOUNCE_RATE = 220;
export const API_CALL_DEBOUNCE_RATE = 650;
export const PHOTO_FILE_SIZE_LIMITATION = 5000000;
export const PHOTO_FILE_TYPE = "image/x-png,image/jpeg";
export const VIDEO_ASPECT_RATIO = 9 / 16;
export const SEARCH_TILE_ASPECT_RATIO = 2 / 3;
export const SEO_KEYWORDS =
  "TenSixty, 1060, musically, mobile video, short-form video, real estate, property ,properties, agents, keywords, home, apartment, house, townhouse, vila, studio, unit, rental, rent, buy, sold, luxury, discovery";
export const SEO_DESCRIPTION =
  "TenSixty is a new social network app for real estate. Watch an endless stream of the world's most amazing houses through short form videos.";
