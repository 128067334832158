import { FunctionComponent } from "react";
import { Spin } from "antd";
import { colors } from "../../../commons/style/colors";

interface LoadingPaneProps {}

const LoadingPane: FunctionComponent<LoadingPaneProps> = ({}) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: colors.white,
      }}
    >
      <Spin size="large" />
    </div>
  );
};

export default LoadingPane;
