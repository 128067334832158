export const PropertyViewConstants = {
  SEARCH_PROPERTY_BY_ID: (propertyId: number) =>
    `/np/api/v1/properties/search/byid?propertyId=${propertyId.toString()}`,

  NEARBY_PROPERTIES_URL: (lat?: number, lng?: number, ip?: string) =>
    `np/api/v1/properties/nearby?lat=${lat?.toString()}&long=${lng?.toString()}&ip=${ip}&includeVideos=true&limit=${
      PropertyViewConstants.DEFAULT_PAGE_SIZE
    }&campaignType=PRIVATE_SALE`,

  DEFAULT_PAGE_SIZE: 20,
};
